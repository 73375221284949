<template>
  <div class="warranty-content">
    <!-- 质保查询 -->
    <suspense>
      <div
        v-if="router.currentRoute.value.name === 'Warranty'"
        class="warranty-home"
      >
        <Header></Header>
        <Main></Main>
      </div>
      <router-view v-else></router-view>
    </suspense>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import Header from "@/views/warranty/header";
import Main from "@/views/warranty/main";

/* VUE插件注册-START */
const router = useRouter();
/* VUE插件注册-END */

/* 变量初始化-START */
/* 变量初始化-END */

/* 函数初始化-START */
/* 函数初始化-END */
</script>

<style lang="scss">
.warranty-content {
  width: 100%;
  height: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: PingFangSC;
  .warranty-home {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .van-doc-demo-block__title {
    margin: 0;
    padding: 32px 16px 16px;
    color: rgba(69, 90, 100, 0.6);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
