<template>
  <div class="warranty-header">
    <van-sticky>
      <!-- 顶栏-START -->
      <van-row>
        <van-col span="12" class="warranty-user"
          >合作商：<span>{{ store.getters.name }}</span></van-col
        >
        <van-col span="12" class="warranty-search">
          <van-popover
            v-model:show="showPopup"
            placement="bottom-start"
            style="max-height: 200px; overflow: auto"
          >
            <van-list
              v-model:loading="loading"
              class="warrnaty-search-list"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <van-cell
                @click="chooseList(item.mac)"
                v-for="item in data.listData"
                :key="item.mac"
                :title="item.mac"
              />
            </van-list>
            <template #reference>
              <van-field
                v-model="mac"
                label=""
                clearable
                class="warranty-search-field"
                size="large"
                left-icon="search"
                @update:model-value="searchTerminal(1)"
                @focus="searchTerminal(1)"
                @click-left-icon="searchTerminal(1)"
                @click-right-icon="callCode()"
                :formatter="formatter"
                placeholder="请输入"
                autocomplete="off"
              />
            </template>
          </van-popover>
        </van-col>
      </van-row>
      <!-- 顶栏-END -->
    </van-sticky>
  </div>
</template>
<script setup>
import wx from "weixin-js-sdk";
import { ref, reactive } from "vue";
import { Dialog, Toast } from "vant";
import { useRouter } from "vue-router";
import store from "@/store/index.js";

import { getWxConfig } from "@/api/weixin";
import { BatchProcessMac, stringToMac } from "@/utils/utils";
import { getTerminalList } from "@/api/warranty";

/* VUE插件注册-START */
const router = useRouter();
/* VUE插件注册-END */

/* 变量初始化-START */
const user_id = store.getters.userid;
const corp_id = store.getters.corp_id;
/* 终端MAC */
const mac = ref(null);
/* 终端数据 */
const data = reactive({
  listData: [],
  page: 1,
  limit: 10,
});
/* 搜索框下拉列表 */
const showPopup = ref(false);
const loading = ref(false);
const finished = ref(false);
/* 记录首次加载 */
const firstLoad = ref(true);
/* 遮罩层 */
const toast = ref(null);
/* 变量初始化-END */

/* 函数初始化-START */
/* 调用扫码 */
const callCode = () => {
  toast.value = Toast.loading({
    duration: 0,
    forbidClick: true,
  });
  let ua = navigator.userAgent.toLowerCase();
  let newUrl = "";
  if (/iphone|ipad|ipod/.test(ua)) {
    newUrl = window.location.href.split("#")[0];
  } else if (/android/.test(ua)) {
    newUrl = window.location.href;
  }
  getWeiXinConfig(newUrl);
};
/* 获取企业微信验证相关配置 */
const getWeiXinConfig = (newUrl) => {
  getWxConfig({ url: newUrl, corp_id: corp_id }).then(
    (res) => {
      toast.value.clear();
      initWeiXinSDK(res);
    },
    (err) => {
      toast.value.clear();
      Dialog({ message: "调用扫码失败" });
      console.log(err);
    }
  );
};
/* 初始化企业微信JS-SDK */
const initWeiXinSDK = (jsondata) => {
  wx.config({
    beta: true,
    debug: false,
    appId: jsondata.CorpId,
    timestamp: jsondata.timestamp,
    nonceStr: jsondata.nonceStr,
    signature: jsondata.signature,
    jsApiList: [
      "checkJsApi",
      "onMenuShareTimeline",
      "onMenuShareAppMessage",
      "onMenuShareQQ",
      "onMenuShareWeibo",
      "hideMenuItems",
      "showMenuItems",
      "hideAllNonBaseMenuItem",
      "showAllNonBaseMenuItem",
      "translateVoice",
      "startRecord",
      "stopRecord",
      "onRecordEnd",
      "playVoice",
      "pauseVoice",
      "stopVoice",
      "uploadVoice",
      "downloadVoice",
      "chooseImage",
      "previewImage",
      "uploadImage",
      "downloadImage",
      "getNetworkType",
      "openLocation",
      "getLocation",
      "hideOptionMenu",
      "showOptionMenu",
      "closeWindow",
      "scanQRCode",
      "chooseWXPay",
      "openProductSpecificView",
      "addCard",
      "chooseCard",
      "openCard",
    ],
  });
  wx.error(function (res) {
    Dialog({ message: "出错了：" + res.errMsg }); //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
  });
  wx.ready(function () {
    wx.scanQRCode({
      desc: "scanQRCode desc",
      needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
      scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是条形码（一维码），默认二者都有
      success: function (res) {
        // 回调
        var result = res.resultStr; //当needResult为1时返回处理结果
        mac.value = result;
        const query_info = BatchProcessMac(result);
        if (!query_info.result) {
          Dialog({
            message:
              "以下终端格式错误，请输入正确格式的终端（" +
              query_info.info +
              "）",
          });
          return false;
        }
        router.push({
          path: "/warranty/details",
          query: { mac: query_info.info },
        });
      },
      error: function (res) {
        if (res.errMsg.indexOf("function_not_exist") > 0) {
          Dialog({ message: "版本过低请升级" });
        }
      },
    });
  });
};

/* 输入终端MAC时格式化-只允许输入16进制数和冒号 */
const formatter = (value) => value.replace(/[^0-9a-fA-F\\:]/g, "");
/* 终端搜索框下拉表 */
const onLoad = () => {
  if (!user_id) {
    return false;
  }
  // 异步更新数据
  searchTerminal(2);
};
/* 查询终端列表 */
const searchTerminal = (type = 1) => {
  if (!user_id) {
    return false;
  }
  if (firstLoad.value) {
    data.listData = [];
    firstLoad.value = false;
    return false;
  }
  if (type == 1) {
    data.listData = [];
    data.page = 1;
    finished.value = false;
  }
  let searchMac = mac.value;
  if (!/:/.test(searchMac)) {
    searchMac = stringToMac(searchMac);
  }
  getTerminalList({
    mac: searchMac,
    page: data.page,
    limit: data.limit,
    user_id: user_id,
  }).then(
    (res) => {
      loading.value = false;
      if (res.data && res.data.code && res.data.code == 200) {
        if (data.page == 1) {
          data.listData = [];
        }
        data.listData = [...data.listData, ...res.data.data];
        data.page += 1;
        if (data.listData.length >= res.data.total) {
          finished.value = true;
        }
        showPopup.value = true;
      } else {
        finished.value = true;
      }
    },
    (err) => {
      Toast.fail(err);
      loading.value = false;
      finished.value = true;
    }
  );
};
/* 终端下拉列表点击回调 */
const chooseList = (value) => {
  mac.value = value;
  showPopup.value = false;
  queryTerminalInfo();
};
/* 查询终端信息 */
const queryTerminalInfo = () => {
  const valid_info = mac.value ? mac.value.trim() : "";
  toast.value = Toast.loading({
    duration: 0,
    forbidClick: true,
  });
  if (!valid_info) {
    toast.value.clear();
    Dialog({ message: "请输入需要查询的终端" });
    return false;
  }
  const query_info = BatchProcessMac(valid_info);
  if (!query_info.result) {
    toast.value.clear();
    Dialog({
      message:
        "以下终端格式错误，请输入正确格式的终端（" + query_info.info + "）",
    });
    return false;
  }
  toast.value.clear();
  router.push({ path: "/warranty/details", query: { mac: query_info.info } });
};
/* 函数初始化-END */
</script>

<style lang="scss">
.warranty-header {
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  border-style: none;
  border-color: unset;
  font-size: 14px;
  padding: 8px;
  font-weight: normal;
  font-style: normal;
  .warranty-user {
    height: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    font-style: normal;
    letter-spacing: 0px;
    line-height: 32px;
    text-decoration: none;
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      color: #333;
    }
  }
  .warranty-search {
    height: 30px;
    .warranty-search-field {
      height: 30px;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      padding: 0 8px;
      line-height: 30px;
      font-size: 13px;
      font-weight: 400;
      color: #999999;
    }
  }
}
</style>
