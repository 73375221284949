<template>
  <div class="warranty-main">
    <!-- 质保概况-START -->
    <div class="warranty-overview">
      <div class="warranty-overview-header warranty-title overview">
        <span class="title-overview-left">终端质保概况</span>
        <span class="title-overview-right">
          <span @click="queryWarranty()">我的延保</span>
          <!-- <span @click="applyWarranty()">申请延保</span> -->
        </span>
      </div>
      <div class="warranty-overview-main">
        <van-grid :border="false" :column-num="2">
          <van-grid-item>
            <div class="warranty-overview-main-title">质保期内总终端数</div>
            <div class="warranty-overview-main-num">
              {{ pageData.warrantyNumber }}
            </div>
          </van-grid-item>
          <van-grid-item>
            <div class="warranty-overview-main-title">过保总终端数</div>
            <div class="warranty-overview-main-num">
              {{ pageData.expireNumber }}
            </div>
          </van-grid-item>
        </van-grid>
        <van-grid :column-num="3">
          <van-grid-item>
            <div class="warranty-overview-main-title">可换新终端数</div>
            <div class="warranty-overview-main-num total-black">
              {{ pageData.renewNumber }}
            </div>
            <div class="warranty-overview-main-ratio">
              {{ pageData.renewRatio }}%
            </div>
          </van-grid-item>
          <van-grid-item>
            <div class="warranty-overview-main-title">可保修终端数</div>
            <div class="warranty-overview-main-num total-black">
              {{ pageData.warrantyNumber }}
            </div>
            <div class="warranty-overview-main-ratio">
              {{ pageData.warrantyRatio }}%
            </div>
          </van-grid-item>
          <van-grid-item>
            <div class="warranty-overview-main-title">过保终端数</div>
            <div class="warranty-overview-main-num total-black">
              {{ pageData.expireNumber }}
            </div>
            <div class="warranty-overview-main-ratio">
              {{ pageData.expireRatio }}%
            </div>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
    <!-- 质保概况-END -->

    <!-- 质保终端型号分布-START -->
    <div class="warranty-model">
      <div class="warranty-model-header warranty-title model">
        质保终端型号分布
      </div>
      <div class="warranty-model-main">
        <!-- <div class="warranty-model-title clearfix">
          <van-radio-group
            v-model="terminalModel"
            direction="horizontal"
            class="model-info"
          >
            <van-radio name="1">可换新</van-radio>
            <van-radio name="2">可保修</van-radio>
            <van-radio name="3">已过保</van-radio>
          </van-radio-group>
        </div> -->
        <div class="warranty-model-chart clearfix">
          <div id="modelChart"></div>
        </div>
      </div>
    </div>
    <!-- 质保终端型号分布-END -->

    <!-- 质保到期日期分布-START -->
    <div class="warranty-date">
      <div class="warranty-date-header warranty-title date">
        质保到期日期分布
      </div>
      <div class="warranty-date-main">
        <van-row>
          <van-col span="8">日期</van-col>
          <van-col span="8">过换新终端数</van-col>
          <van-col span="8">过保修终端数</van-col>
        </van-row>
        <div v-if="pageData.warrantyDateList.length > 0">
          <van-row
            v-for="(item, index) in pageData.warrantyDateList"
            :key="index"
          >
            <van-col span="8">{{ item.time }}</van-col>
            <van-col span="8">{{ item.renewCount }}</van-col>
            <van-col span="8">{{ item.timeDeprotectionCount }}</van-col>
          </van-row>
        </div>
        <van-empty v-else description="暂无数据" />
      </div>
    </div>
    <!-- 质保到期日期分布-END -->
  </div>
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import store from "@/store/index.js";
import { Toast } from "vant";

import {
  getTerminalWarranty,
  getTerminalWarrantyModel,
  getTerminalWarrantyDate,
} from "@/api/warranty";

import * as echarts from "echarts";
// const { proxy } = getCurrentInstance();

/* VUE插件注册-START */
const router = useRouter();
/* VUE插件注册-END */

/* 变量初始化-START */
const user_id = store.getters.userid;

/* 页面动态数据 */
const pageData = reactive({
  renewNumber: 0 /* 可换新终端数量 */,
  renewRatio: 0 /* 可换新终端占比 */,
  warrantyNumber: 0 /* 可保修终端数量 */,
  warrantyRatio: 0 /* 可保修终端占比 */,
  expireNumber: 0 /* 过保修终端数量 */,
  expireRatio: 0 /* 过保修终端占比 */,
  warrantyDateList: [] /* 质保日期列表 */,
  chartData: [["product", "可换新", "可保修", "已过保"]] /* 图表数据 */,
});
/* 变量初始化-END */

/* 统计图表-START */
let myChart;
//配置项函数
const chartOption = (data) => {
  myChart.setOption({
    legend: {},
    tooltip: {},
    color: [
      "rgba(91, 143, 249, 0.85)",
      "rgba(90, 216, 166, 0.85)",
      "rgba(93, 112, 146, 0.85)",
    ],
    dataset: {
      source: data,
    },
    grid: {
      left: 10,
      right: 10,
      bottom: 0,
      containLabel: true,
    },
    dataZoom: {
      type: "inside",
    },
    xAxis: {
      type: "category",
      axisLabel: {
        show: true,
        interval: 0,
        rotate: 90,
      },
    },
    yAxis: {},
    series: [
      {
        type: "bar",
      },
      {
        type: "bar",
      },
      {
        type: "bar",
      },
    ],
  });
};
/* 统计图表-END */
onMounted(() => {
  myChart = echarts.getInstanceByDom(document.getElementById("modelChart")); //有的话就获取已有echarts实例的DOM节点。
  if (myChart != null && myChart != "" && myChart != undefined) {
    // 如果存在，就进行注销。
    myChart.dispose();
  }
  // 基于准备好的dom，初始化echarts实例
  myChart = echarts.init(document.getElementById("modelChart"));
  // 绘制图表
  chartOption(pageData.chartData);
  window.onresize = function () {
    //自适应大小
    myChart.resize();
  };
});

/* 函数初始化-START */
/* 我的延保 */
const queryWarranty = () => {
  router.push({ path: "/warranty/list" });
};
/* 申请延保 */
// const applyWarranty = () => {
//   router.push({ path: "/warranty/apply" });
// };
/* 页面数据加载 */
const init_data = () => {
  if (!user_id) {
    return false;
  }
  /* 终端质保概况 */
  const toast1 = Toast.loading({
    message: "加载中...",
    forbidClick: true,
  });
  getTerminalWarranty({ user_id: user_id })
    .then((result) => {
      toast1.clear();
      if (result.data && result.data.code && result.data.code == 200) {
        const resData = result.data.data;
        pageData.renewNumber = resData.renewCount ? +resData.renewCount : 0;
        pageData.warrantyNumber = resData.guaranteeCount
          ? +resData.guaranteeCount
          : 0;
        pageData.expireNumber = resData.deprotectionCount
          ? +resData.deprotectionCount
          : 0;
        const totalNum =
          pageData.renewNumber +
          pageData.warrantyNumber +
          pageData.expireNumber;
        pageData.renewRatio =
          totalNum == 0
            ? 0
            : ((pageData.renewNumber / totalNum) * 100).toFixed(2);
        pageData.warrantyRatio =
          totalNum == 0
            ? 0
            : ((pageData.warrantyNumber / totalNum) * 100).toFixed(2);
        pageData.expireRatio =
          totalNum == 0
            ? 0
            : ((pageData.expireNumber / totalNum) * 100).toFixed(2);
      }
    })
    .catch(() => {
      toast1.clear();
    });
  /* 质保终端型号分布 */
  const toast2 = Toast.loading({
    message: "加载中...",
    forbidClick: true,
  });
  getTerminalWarrantyModel({ user_id: user_id }).then(
    (result) => {
      toast2.clear();
      if (result.data && result.data.code && result.data.code == 200) {
        let resData = result.data.data;
        resData = resData ? resData : [];
        pageData.chartData = [
          ...[["product", "可换新", "可保修", "已过保"]],
          ...resData,
        ];
        chartOption(pageData.chartData);
      }
    },
    () => {
      toast2.clear();
    }
  );
  /* 质保到期日期分布 */
  const toast3 = Toast.loading({
    message: "加载中...",
    forbidClick: true,
  });
  getTerminalWarrantyDate({ user_id: user_id }).then(
    (result) => {
      if (result.data && result.data.code && result.data.code == 200) {
        const resData = result.data.data ? result.data.data : [];
        pageData.warrantyDateList = resData;
      }
    },
    () => {
      toast3.clear();
    }
  );
};
/* 函数初始化-END */
init_data();
</script>

<style lang="scss">
.warranty-main {
  width: 100%;
  height: calc(100% - 48px);
  box-sizing: border-box;
  overflow: auto;
  padding: 12px;
  .warranty-overview,
  .warranty-model,
  .warranty-date {
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .warranty-title {
    position: relative;
    height: 48px;
    padding: 13px 0px 13px 40px;
    box-sizing: border-box;
    text-decoration: none;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 22px;
    border-bottom: 1px solid #eeeeee;
    .title-overview-right {
      position: absolute;
      right: 0;
      padding: 0 18px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1677ff;
      line-height: 20px;
    }
  }
  .warranty-overview {
    .warranty-overview-main-title {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
      text-decoration: none;
    }
    .warranty-overview-main-num {
      font-weight: 400;
      font-size: 37px;
      color: rgb(239, 148, 27);
      font-style: normal;
      letter-spacing: 0px;
      line-height: 52px;
      text-decoration: none;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(22, 119, 255, 1);
    }
    .warranty-overview-main-ratio {
      font-weight: 400;
      font-size: 12px;
      color: rgba(22, 119, 255, 0.6);
      font-style: normal;
      letter-spacing: 0px;
      line-height: 18px;
      text-decoration: none;
      font-family: PingFangSC-Medium, PingFang SC;
    }
    .warranty-overview-main-title.total-black,
    .warranty-overview-main-num.total-black {
      font-weight: 600;
      color: #333333;
      font-family: PingFangSC-Medium, PingFang SC;
      letter-spacing: 0px;
      text-decoration: none;
    }
    .warranty-overview-main-title.total-black {
      font-size: 12px;
      line-height: 18px;
    }
    .warranty-overview-main-num.total-black {
      font-size: 14px;
      line-height: 25px;
    }
  }
  .model-info {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: rgba(90, 90, 90, 1);
    font-style: normal;
    letter-spacing: 0px;
    line-height: 20px;
    .van-radio[aria-checked="true"] .van-radio__label {
      color: rgb(50, 145, 248);
    }
    .van-radio__icon {
      display: none;
    }
  }
  .warranty-model-chart {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    #modelChart {
      width: 100%;
      height: 200px;
      background-color: rgb(248, 249, 251);
    }
  }
  .warranty-date {
    .warranty-date-main {
      .van-row {
        font-weight: 500;
        font-size: 14px;
        height: 32px;
        color: #666666;
        font-family: PingFangSC-Medium, PingFang SC;
        letter-spacing: 0px;
        line-height: 32px;
        text-decoration: none;
        text-align: center;
      }
      .van-row:nth-of-type(odd) {
        background: rgba(216, 216, 216, 0);
      }
      .van-row:nth-of-type(even) {
        background: rgba(22, 119, 255, 0.02);
      }
      .van-col {
        font-family: ArialMT;
        color: #333333;
      }
    }
  }
  .clearfix {
    *zoom: 1;
  }
  .clearfix:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
</style>
